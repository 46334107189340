.btn {
    box-shadow: 0 5px 6px 0 rgba(55, 76, 9, 0.3);
    // min-width: 28rem;
    padding: 1rem 2rem;
    border-radius: 2rem;
    border: 0.2rem solid $brand-1;
    color: $brand-1;
    text-decoration: none;
    font-family: $font-family;
    font-weight: bold;
    font-size: 1.6rem;
    text-align: center;
    text-align-last: center;
    cursor: pointer;

    &-green {
        background-color: $green;
        border-color: $green;
        color: white;

        &:disabled {
            background-color: #B6BCB9;
            border-color: #B6BCB9;
        }
    }

    &-orange {
        background-color: $orange;
        border-color: $orange;
        color: white;
    }

    &-brand {
        background-color: $brand-1;
        border-color: $brand-1;
        color: white;
    }

    &-block {
        display: block;
        width: 100%;
    }

    &-like {
        background-color: #A1B2BA;
        border-color: #A1B2BA;
        color: white;
        font-size: 2.2rem;
        line-height: 2.2rem;
        padding: 0.6rem 1.7rem;

        &.active {
            background-color: #248DC1;
            border-color: #248DC1;
        }

        &:after {
            content: url(/images/like.svg);
            width: 1.9rem;
            height: 1.9rem;
        }

        @media only screen and (max-width: $responsiveMobile) {
            font-size: 1.8rem;
            line-height: 2rem;
            padding: 0.4rem 1.2rem;

            &:after {
                width: 1.2rem;
                height: 1.2rem;
            }
        }

        span {
            margin-right: 0.8rem;
        }
    }

    &-share {
        background-color: #81BC00;
        border-color: #81BC00;
        color: white;
        font-size: 1.6rem;
        line-height: 1.7rem;
        padding: 1rem 1.7rem;
        z-index: 1;
    }

    &-comment {
        padding-left: 6rem;
        padding-right: 6rem;
        @media only screen and (max-width: $responsiveMobile) {
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
            font-size: 16px;
        }
    }

    &-circle {
        border-radius: 50%;
        padding: 0.5rem 1.8rem;
        font-size: 28px;
    }

    &#landing {
        @media only screen and (max-width: 820px) {
        }
    }
}

.form {
    &-intro {
        margin-bottom: 4rem;
    }

    &-group {
        margin-bottom: 2rem;

        label {
            display: block;
            color: $brand-1;
            font-weight: bold;
            font-size: 1.6rem;
            margin-bottom: 0.5rem;

            &.form-check-label {
                font-weight: normal;
                color: #4A4A49;

                input {

                }
            }
        }
    }

    &-item {
        padding: 4rem 3rem;
        position: relative;
        @include display-flex;

        @media only screen and (max-width: $responsiveMobile) {
            padding: 2rem 1.5rem;
        }

        > div {
            flex-grow: 1;

            &:not(.num) {
                max-width: calc(100% - 4rem);
            }
        }

        .num {
            font-size: 4.8rem;
            font-weight: bold;
            color: #B6BCB9;
            margin-right: 1.5rem;
            width: 2.5rem;
            flex-grow: 0;

            @media only screen and (max-width: $responsiveMobile) {
                font-size: 2.4rem;
                margin-right: 1rem;
            }
        }


        label {
            color: $brand-1;
            font-size: 3.2rem;
            font-weight: bold;

            @media only screen and (max-width: $responsiveMobile) {
                font-size: 2rem;
            }
        }

        p {
            margin-top: 1rem;
            font-size: 1.3rem;
        }

        &.geocode {
            padding: 0rem 3rem 4rem 3rem;

            label {
                color: $brand-1;
                font-size: 1.6rem;
                line-height: 1.7rem;
                font-weight: bold;
                margin-bottom: 0.5rem;
                display: block;
            }

            .mapboxgl-ctrl-geocoder {
                width: 100%;
                max-width: 100%;
                box-shadow: none;

                input {
                    padding-left: 3rem;
                }
            }
        }
    }
}

input[type=text], input[type=email], input[type=password], textarea, select {
    border: 1px solid #B6BCB9;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: #ffffff;
    outline: none;
    font-size: 1.4rem;
    max-width: 100%;

    &:focus {
        border-color: #402051;
    }
}

#geocoder {
    input {
        padding-left: 3rem !important;
    }
}

.radio {
    .option {
        display: block;
        position: relative;
        padding: 3px 10px 0 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-style: solid;
        border-color: $brand-1;
        border-radius: 20px;
        border-width: 0.2rem;
        color: #4A4A49;
        font-weight: normal;
        height: 30px;
        align: left;
        width: fit-content;
    }

    /* Hide the browser's default radio button */
    .option input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 26px;
        width: 26px;
        background-color: #eee;
        border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    .option:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    .option input:checked ~ .checkmark {
        background-color: $brand-1;
        height: 28px;
        width: 28px;
        margin: -0.1rem;
    }

}

textarea {
    height: 158px;
}

.button-group-pills {
    label {
        display: inline-block;
        font-size: 1.6rem;
        font-weight: normal;
        border-radius: 2rem;
        line-height: 1.2;

        margin-bottom: 1.5rem;
        margin-left: 1rem;

        border: 1px solid $brand-1;
        background-color: white;
        color: $brand-1;
        padding: 0.5rem 1rem;
        cursor: pointer;

        @media only screen and (max-width: $responsiveTablet) {
            font-size: 1.2rem;
            padding: 0.3rem 0.6rem;
            margin-left: 0.5rem;
        }

        &.selected {
            border-color: $brand-1;
            background-color: $brand-1;
            color: white;
            font-weight: bold;
            box-shadow: none;
        }

        // &:hover {
        //   border-color:     $pill-hover-border;
        //   background-color: $pill-hover-bg;
        //   color:            $pill-hover-font;
        // }

        input {
            position: absolute;
            clip: rect(0, 0, 0, 0);
            pointer-events: none;
        }
    }
}

#map-geocoder {
    max-width: 35%;
    @media only screen and (max-width: $responsiveMobile) {
        max-width: 100%;
    }
}

// set size and position and size of geocoder search icon
.mapboxgl-ctrl-geocoder--icon-search {
    top: 10px;
    left: 8px;
    width: 20px;
    height: 20px;
}

#errors {
    li {
        color: $error-color;
        list-style-type: none;
    }
}

#image-dropzone {
    background-color: transparent;
    border: 2px dashed $brand-1;
    border-radius: .5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-family: $font-family;

    .dz-default {
        color: $brand-1;
    }

    .dz-preview {
        display: flex;
        width: 100%;
        border-radius: .5rem;
        overflow: hidden;
        margin: 0;

        + .dz-preview {
            margin-top: 1rem;
        }

        .dz-image {
            flex: 0 1 100px;
        }

        .dz-details {
            z-index: 20;
            position: relative;
            flex: 1 1 auto;
            left: 0;
            opacity: 1;
            font-family: $font-family;
            font-size: 15px;
            min-width: 0;
            max-width: none;
            padding: 2em 1em;
            line-height: 1;
            background-color: $brand-1;
            display: flex;
            flex-direction: column-reverse;


            .dz-filename {
                margin-bottom: .5rem;
            }

            .dz-size {
                margin-bottom: 0;
            }
        }

        .dz-remove {
            padding: 0;
            font-size: 0;
            line-height: 0;
            top: 1rem;
            right: 1rem;
            bottom: auto;
            width: 1rem;
            left: auto;
            border: none;
            display: block !important;
            opacity: 1;
            z-index: 51;

            &:after {
                content: url("data:image/svg+xml;base64,PHN2ZwogICAgdmVyc2lvbj0iMS4xIgogICAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICAgdmlld0JveD0iMCAwIDM0IDM0IgogICAgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzQgMzQ7IgogICAgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIKICAgIHdpZHRoPSIxMCIKICAgIGhlaWdodD0iMTAiCj4KICAgIDxwYXRoIGQ9Ik0zMy42LDUuM0w1LjMsMzMuNmwtNC45LTQuOUwyOC43LDAuNEwzMy42LDUuM3oiIGZpbGw9IndoaXRlIj48L3BhdGg+CiAgICA8cGF0aCBkPSJNNS4zLDAuNGwyOC4zLDI4LjNsLTQuOSw0LjlMMC40LDUuM0w1LjMsMC40eiIgZmlsbD0id2hpdGUiPjwvcGF0aD4KPC9zdmc+Cg==");
                height: 1rem;
                width: 1rem;
                display: block;
            }
        }

        &.dz-error {
            .dz-error-mark {
                display: none !important;
            }

            .dz-error-message {
                z-index: 50;
                position: absolute;
                top: 0;
                margin: auto;
                opacity: 1;
                /* max-width: 80%; */
                /* height: 32px; */
                bottom: 0;
                left: 0;
                right: 0;
                border-radius: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(255, 0, 0, .85);
                font-weight: 700;
                font-size: 1.5rem;
            }
        }

        &:hover {
            .dz-image img {
                transform: scale(1, 1);
                filter: blur(0);
            }
        }
    }
}
