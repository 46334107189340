.card {
    border-radius: 2rem;
    box-shadow: 0 2px 8px 0 $box-shadow;
    background: #ffffff;
    padding: 4rem;

    &.card--popup {
        position: fixed;
        z-index: 1002;
        top: 10rem;
        left: 0;
        right: 0;
        padding: 4rem 2rem 3rem;
        margin: 0 auto;
        width: fit-content;
        border-radius: 2rem;

        .cookie-confirmation__close {
            margin: 1.5rem 2rem;
        }

        .card__content {
            h2 {
                margin-bottom: 0;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    @media only screen and (max-width: $responsiveMobile) {
        padding: 2rem;
        border-radius: 1.5rem;
    }

    &-header {
        margin-bottom: 3rem;
    }

    h1 {
        margin-top: 0;

        @media only screen and (max-width: $responsiveMobile) {
            font-size: 2.6rem;
            line-height: 2.8rem;
        }
    }

    p {
        @media only screen and (max-width: $responsiveMobile) {
            font-size: 1.3rem;
        }
    }

    &.auth {
        margin-top: 10rem;

        @media only screen and (max-width: $responsiveMobile) {
            margin-top: 6rem;
        }
    }

    h2 {
        color: $brand-1;
    }
}

#map-wrapper {
    #map {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        top: 90px;
        margin-left: $sidebar-width;

        &.wide{
            margin-left: 20px;
            width: calc(100% - 20px);
        }

        @media only screen and (max-width: $responsiveTablet) {
            margin-left: $sidebar-width-tablet;
        }
    }

    @media only screen and (max-width: $responsiveMobile) {
        padding: 1.5rem 3rem 1.5rem 1.5rem;

        #tooltip {
            display: none;
        }

        #map {
            position: relative;
            width: 100%;
            margin-left: 0;
            top: initial;
            bottom: initial;
            left: initial;
            right: initial;
            height: 35rem;
        }
    }
}

#sidebar {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 90px;
    width: $sidebar-width;
    min-height: calc(100vh - 90px);
    padding: 0 0 40px 0;
    box-shadow: 0 2px 8px 0 $box-shadow;
    overflow-y: auto;
    overflow-x: hidden;
    // z-index: 2;
    // FIXME: Might be temp
    z-index: 1002;
    background-color: white;

    @media only screen and (max-width: $responsiveTablet) {
        width: $sidebar-width-tablet;
    }

    @media only screen and (max-width: $responsiveMobile) {
        position: static;
        width: 100%;
        overflow: initial;
        padding-top: 0.5rem;
        padding-bottom: 0;
        box-shadow: none;
        min-height: 0;

        p {
            font-size: 1.3rem;
        }
    }

    h1 {
        margin-top: 4rem;

        @media only screen and (max-width: $responsiveMobile) {
            margin-top: 2rem;
            margin-bottom: 1rem;
            font-size: 2.6rem;
            line-height: 2.8rem;
        }
    }

    .close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        cursor: pointer;
        color: $brand-1;
        font-weight: 700;

        @media only screen and (max-width: $responsiveMobile) {
            top: 1rem;
            right: 1rem;
        }

        svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    &.narrow{
        width: 28px;
        overflow-y: hidden;
        cursor: pointer;
    }

    .chevron
    {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateX(-37px) rotate(-90deg);
        cursor: pointer;
        z-index: 1000;
        width: 100px;
        font-weight: 700;
        color: $brand-1;
    }
}

.view-comment {
    position: relative;

    .alert {
        background-color: $brand-1;
        padding: 1.8rem 4rem;
        line-height: 2.2rem;

        @media only screen and (max-width: $responsiveMobile) {
            padding: 1rem 2rem;
        }

        span {
            font-size: 2rem;
            font-weight: bold;
            color: white;

            @media only screen and (max-width: $responsiveMobile) {
                font-size: 1.4rem;
            }
        }

        + .alert {
            border-top: 1px solid white;
        }
    }

    .header {
        padding: 4rem;

        @media only screen and (max-width: $responsiveMobile) {
            padding: 2rem;
        }

        span {
            font-size: 1.3rem;
            font-weight: bold;
            display: inline-block;

            &.date {
                color: $grey;
                font-weight: normal;
                margin-left: 0.2rem;
            }
        }

        h1 {
            margin-top: 1rem !important;
            margin-bottom: 0;
        }
    }

    .comment-images {
        ul {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                flex: 0 0 calc(33% - 1rem);

                a {
                    border-radius: 1rem;
                    border: .1rem solid #C6C9CB;
                    padding: .5rem;
                    display: flex;
                    min-height: 14rem;
                    overflow: hidden;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: .5rem;
                        overflow: hidden;
                        object-fit: cover;


                    }

                    &.unmoderated {
                        overflow: hidden;

                        img {
                            -ms-filter: "progid:DXImageTransform.Microsoft.MotionBlur(strength=50)";
                            -webkit-filter: blur(15px);
                            filter: blur(15px);
                        }
                    }
                }

                + li {
                    margin-left: 1rem;
                }

                @media screen and (max-width: $responsiveTablet){
                    flex: 0 0 100%;
                }

                @media screen and (max-width: $responsiveMobile){
                    max-width: 26rem;
                }
            }
        }
    }

    .body {
        padding: 3rem 4rem;
        background-color: white;

        @media only screen and (max-width: $responsiveMobile) {
            padding: 2rem;
        }

        h2 {
            color: $brand-1;
            font-size: 3.2rem;
            line-height: 3.4rem;
            margin-top: 0;

            @media only screen and (max-width: $responsiveMobile) {
                font-size: 2.4rem;
                line-height: 2.6rem;
            }

            span {
                color: $grey;
            }
        }

        img{
            right: 00;
            position: absolute;
            height: 7rem;
            margin: 30px;
        }
    }

    .footer {
        padding: 0 4rem;
        background-color: white;

        @media only screen and (max-width: $responsiveMobile) {
            margin-bottom: 2rem;
            padding: 0 2rem;
        }
    }

    svg {
        path {
            fill: #8A8D8E;
        }
    }

    &.image-not-moderated {
        svg path {
            fill: white;
        }
    }

    &.not-moderated {
        .header, .body h2 {
            opacity: 0.6;
        }

        svg path {
            fill: white;
        }
    }
}

.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    display: none;

    &.open {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__backdrop {
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
    }

    &__inner {
        display: flex;
        position: relative;
        z-index: 3;
        height: calc(95vh - 9rem);
        max-width: 95vw;
        width: auto;
        top: 4rem;
        flex: 0 1 auto;
        padding: 20px;
        border-radius: 5px;
        background-color: white;
        box-shadow: 0 0.4rem 2rem -1.2rem rgb(14 27 42 / 50%);

        @media screen and (max-width: $responsiveMobile){
            height: auto;
        }
    }

    &__element {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        display: block;
        object-fit: contain;
    }

    &__close {
        position: absolute;
        right: 3rem;
        top: 3rem;
    }
}

.modal-close {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;

    &__text {
        color: white;
        font-size: 13px;
        line-height: 1;
        font-weight: 700;
        margin-right: 1rem;
    }

    &__icon {
        fill: white;
        height: 13px;
        width: 13px;
    }
}

.landing-page {
    margin-bottom: 0rem;

    p {
        margin-bottom: 2rem;
    }

    .btn {
        display: inline-block;
        margin-bottom: 3rem;
        // @media only screen and (max-width: 1060px) and (min-width: 767px){
        // 	padding: 2rem 0rem;
        // }
        // @media only screen and (max-width: 452px){
        // 	padding: 2rem 0rem;
        // }
    }

    img {
        vertical-align: middle;
        padding: 4px;
    }
}

#logo-container {
    display: flex;
}

#logo-wg {
    width: 21rem;
    height: 7rem;
    background: url('/images/wg-logo.png') no-repeat;
    background-size: contain;

    &.landing {
        bottom: 0;
        right: 0;
        display: inline-block;
        margin: 0rem 0 3rem 0;
        // float: right;
        @media only screen and (max-width: 1060px) {
            position: relative;
            @media only screen and (max-width: $responsiveTablet) {
                display: inline-block;
                width: 15rem;
                height: 5rem;
                margin: 0 0 3rem 0;
                float: right;
            }
        }
    }

    &.map {
        display: none;
        @media only screen and (max-width: $responsiveMobile) {
            position: relative;
            width: 15rem;
            height: 5rem;
            display: block;
            float: right;
        }
    }
}

*, *:focus, *:hover {
    outline: none;
}
