@import "~nouislider/distribute/nouislider.css";

@import "~@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

:root {
  --reflex-columns: 12;
  --reflex-grid-spacing: 15px;
  --reflex-xs: 576px;
  --reflex-sm: 768px;
  --reflex-md: 992px;
  --reflex-lg: 1200px;
  --reflex-xlg: 1600px;
  --reflex-xxs-max: 575px;
  --reflex-xs-max: 767px;
  --reflex-sm-max: 991px;
  --reflex-md-max: 1199px;
  --reflex-lg-max: 1599px;
}

.container,
.container-full {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}

.container .grid,
.container-full .grid {
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1600px;
  }
}

.grid {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto;
  position: relative;
  letter-spacing: -0.31em;
  *letter-spacing: normal;
  word-spacing: -0.43em;
  list-style-type: none;
}

.grid::before,
.grid::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
}

[class*=col-] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
  position: relative;
  width: 100%;
  vertical-align: top;
  padding: 15px;
  display: inline-block;
  *display: inline;
  zoom: 1;
}

[class*=col-]::before,
[class*=col-]::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
}

[class*=col-] .grid {
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: -15px;
}

.col-12 {
  width: 100%;
  *width: 99.9%;
}

.col-11 {
  width: 91.6666666667%;
  *width: 91.5666666667%;
}

.col-10 {
  width: 83.3333333333%;
  *width: 83.2333333333%;
}

.col-9 {
  width: 75%;
  *width: 74.9%;
}

.col-8 {
  width: 66.6666666667%;
  *width: 66.5666666667%;
}

.col-7 {
  width: 58.3333333333%;
  *width: 58.2333333333%;
}

.col-6 {
  width: 50%;
  *width: 49.9%;
}

.col-5 {
  width: 41.6666666667%;
  *width: 41.5666666667%;
}

.col-4 {
  width: 33.3333333333%;
  *width: 33.2333333333%;
}

.col-3 {
  width: 25%;
  *width: 24.9%;
}

.col-2 {
  width: 16.6666666667%;
  *width: 16.5666666667%;
}

.col-1 {
  width: 8.3333333333%;
  *width: 8.2333333333%;
}

@media (min-width: 576px) {
  .col-xs-12 {
    width: 100%;
    *width: 99.9%;
  }

  .col-xs-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }

  .col-xs-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }

  .col-xs-9 {
    width: 75%;
    *width: 74.9%;
  }

  .col-xs-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }

  .col-xs-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }

  .col-xs-6 {
    width: 50%;
    *width: 49.9%;
  }

  .col-xs-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }

  .col-xs-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }

  .col-xs-3 {
    width: 25%;
    *width: 24.9%;
  }

  .col-xs-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }

  .col-xs-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}

@media (min-width: 768px) {
  .col-sm-12 {
    width: 100%;
    *width: 99.9%;
  }

  .col-sm-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }

  .col-sm-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }

  .col-sm-9 {
    width: 75%;
    *width: 74.9%;
  }

  .col-sm-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }

  .col-sm-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }

  .col-sm-6 {
    width: 50%;
    *width: 49.9%;
  }

  .col-sm-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }

  .col-sm-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }

  .col-sm-3 {
    width: 25%;
    *width: 24.9%;
  }

  .col-sm-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }

  .col-sm-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}

@media (min-width: 992px) {
  .col-md-12 {
    width: 100%;
    *width: 99.9%;
  }

  .col-md-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }

  .col-md-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }

  .col-md-9 {
    width: 75%;
    *width: 74.9%;
  }

  .col-md-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }

  .col-md-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }

  .col-md-6 {
    width: 50%;
    *width: 49.9%;
  }

  .col-md-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }

  .col-md-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }

  .col-md-3 {
    width: 25%;
    *width: 24.9%;
  }

  .col-md-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }

  .col-md-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}

@media (min-width: 1200px) {
  .col-lg-12 {
    width: 100%;
    *width: 99.9%;
  }

  .col-lg-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }

  .col-lg-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }

  .col-lg-9 {
    width: 75%;
    *width: 74.9%;
  }

  .col-lg-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }

  .col-lg-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }

  .col-lg-6 {
    width: 50%;
    *width: 49.9%;
  }

  .col-lg-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }

  .col-lg-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }

  .col-lg-3 {
    width: 25%;
    *width: 24.9%;
  }

  .col-lg-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }

  .col-lg-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}

@media (min-width: 1600px) {
  .col-xlg-12 {
    width: 100%;
    *width: 99.9%;
  }

  .col-xlg-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }

  .col-xlg-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }

  .col-xlg-9 {
    width: 75%;
    *width: 74.9%;
  }

  .col-xlg-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }

  .col-xlg-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }

  .col-xlg-6 {
    width: 50%;
    *width: 49.9%;
  }

  .col-xlg-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }

  .col-xlg-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }

  .col-xlg-3 {
    width: 25%;
    *width: 24.9%;
  }

  .col-xlg-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }

  .col-xlg-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}

.col-auto {
  -ms-flex: 1 0 0px;
  -webkit-flex: 1 0 0px;
  flex: 1 0 0px;
  width: auto;
}

@media (min-width: 576px) {
  .col-xs-auto {
    -ms-flex: 1 0 0px;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}

@media (min-width: 768px) {
  .col-sm-auto {
    -ms-flex: 1 0 0px;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}

@media (min-width: 992px) {
  .col-md-auto {
    -ms-flex: 1 0 0px;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}

@media (min-width: 1200px) {
  .col-lg-auto {
    -ms-flex: 1 0 0px;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}

@media (min-width: 1600px) {
  .col-xlg-auto {
    -ms-flex: 1 0 0px;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}

.order-12 {
  -ms-flex-order: 12;
  -webkit-order: 12;
  order: 12;
}

.order-11 {
  -ms-flex-order: 11;
  -webkit-order: 11;
  order: 11;
}

.order-10 {
  -ms-flex-order: 10;
  -webkit-order: 10;
  order: 10;
}

.order-9 {
  -ms-flex-order: 9;
  -webkit-order: 9;
  order: 9;
}

.order-8 {
  -ms-flex-order: 8;
  -webkit-order: 8;
  order: 8;
}

.order-7 {
  -ms-flex-order: 7;
  -webkit-order: 7;
  order: 7;
}

.order-6 {
  -ms-flex-order: 6;
  -webkit-order: 6;
  order: 6;
}

.order-5 {
  -ms-flex-order: 5;
  -webkit-order: 5;
  order: 5;
}

.order-4 {
  -ms-flex-order: 4;
  -webkit-order: 4;
  order: 4;
}

.order-3 {
  -ms-flex-order: 3;
  -webkit-order: 3;
  order: 3;
}

.order-2 {
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
}

.order-1 {
  -ms-flex-order: 1;
  -webkit-order: 1;
  order: 1;
}

.order-0 {
  -ms-flex-order: 0;
  -webkit-order: 0;
  order: 0;
}

@media (min-width: 576px) {
  .order-xs-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }

  .order-xs-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }

  .order-xs-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }

  .order-xs-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }

  .order-xs-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }

  .order-xs-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }

  .order-xs-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }

  .order-xs-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }

  .order-xs-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }

  .order-xs-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }

  .order-xs-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }

  .order-xs-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  .order-xs-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}

@media (min-width: 768px) {
  .order-sm-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }

  .order-sm-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }

  .order-sm-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }

  .order-sm-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }

  .order-sm-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }

  .order-sm-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }

  .order-sm-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }

  .order-sm-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }

  .order-sm-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }

  .order-sm-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }

  .order-sm-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }

  .order-sm-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  .order-sm-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}

@media (min-width: 992px) {
  .order-md-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }

  .order-md-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }

  .order-md-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }

  .order-md-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }

  .order-md-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }

  .order-md-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }

  .order-md-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }

  .order-md-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }

  .order-md-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }

  .order-md-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }

  .order-md-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }

  .order-md-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  .order-md-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}

@media (min-width: 1200px) {
  .order-lg-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }

  .order-lg-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }

  .order-lg-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }

  .order-lg-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }

  .order-lg-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }

  .order-lg-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }

  .order-lg-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }

  .order-lg-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }

  .order-lg-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }

  .order-lg-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }

  .order-lg-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }

  .order-lg-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  .order-lg-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}

@media (min-width: 1600px) {
  .order-xlg-12 {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }

  .order-xlg-11 {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }

  .order-xlg-10 {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }

  .order-xlg-9 {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }

  .order-xlg-8 {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }

  .order-xlg-7 {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }

  .order-xlg-6 {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }

  .order-xlg-5 {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }

  .order-xlg-4 {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }

  .order-xlg-3 {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }

  .order-xlg-2 {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }

  .order-xlg-1 {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }

  .order-xlg-0 {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}

.offset-11 {
  margin-left: 91.6666666667%;
  *margin-left: 91.5666666667%;
}

.offset-10 {
  margin-left: 83.3333333333%;
  *margin-left: 83.2333333333%;
}

.offset-9 {
  margin-left: 75%;
  *margin-left: 74.9%;
}

.offset-8 {
  margin-left: 66.6666666667%;
  *margin-left: 66.5666666667%;
}

.offset-7 {
  margin-left: 58.3333333333%;
  *margin-left: 58.2333333333%;
}

.offset-6 {
  margin-left: 50%;
  *margin-left: 49.9%;
}

.offset-5 {
  margin-left: 41.6666666667%;
  *margin-left: 41.5666666667%;
}

.offset-4 {
  margin-left: 33.3333333333%;
  *margin-left: 33.2333333333%;
}

.offset-3 {
  margin-left: 25%;
  *margin-left: 24.9%;
}

.offset-2 {
  margin-left: 16.6666666667%;
  *margin-left: 16.5666666667%;
}

.offset-1 {
  margin-left: 8.3333333333%;
  *margin-left: 8.2333333333%;
}

@media (min-width: 576px) {
  .offset-xs-11 {
    margin-left: 91.6666666667%;
    *margin-left: 91.5666666667%;
  }

  .offset-xs-10 {
    margin-left: 83.3333333333%;
    *margin-left: 83.2333333333%;
  }

  .offset-xs-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .offset-xs-8 {
    margin-left: 66.6666666667%;
    *margin-left: 66.5666666667%;
  }

  .offset-xs-7 {
    margin-left: 58.3333333333%;
    *margin-left: 58.2333333333%;
  }

  .offset-xs-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .offset-xs-5 {
    margin-left: 41.6666666667%;
    *margin-left: 41.5666666667%;
  }

  .offset-xs-4 {
    margin-left: 33.3333333333%;
    *margin-left: 33.2333333333%;
  }

  .offset-xs-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .offset-xs-2 {
    margin-left: 16.6666666667%;
    *margin-left: 16.5666666667%;
  }

  .offset-xs-1 {
    margin-left: 8.3333333333%;
    *margin-left: 8.2333333333%;
  }

  .offset-xs-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}

@media (min-width: 768px) {
  .offset-sm-11 {
    margin-left: 91.6666666667%;
    *margin-left: 91.5666666667%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
    *margin-left: 83.2333333333%;
  }

  .offset-sm-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
    *margin-left: 66.5666666667%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
    *margin-left: 58.2333333333%;
  }

  .offset-sm-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
    *margin-left: 41.5666666667%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
    *margin-left: 33.2333333333%;
  }

  .offset-sm-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
    *margin-left: 16.5666666667%;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
    *margin-left: 8.2333333333%;
  }

  .offset-sm-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}

@media (min-width: 992px) {
  .offset-md-11 {
    margin-left: 91.6666666667%;
    *margin-left: 91.5666666667%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
    *margin-left: 83.2333333333%;
  }

  .offset-md-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
    *margin-left: 66.5666666667%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
    *margin-left: 58.2333333333%;
  }

  .offset-md-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
    *margin-left: 41.5666666667%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
    *margin-left: 33.2333333333%;
  }

  .offset-md-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
    *margin-left: 16.5666666667%;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
    *margin-left: 8.2333333333%;
  }

  .offset-md-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}

@media (min-width: 1200px) {
  .offset-lg-11 {
    margin-left: 91.6666666667%;
    *margin-left: 91.5666666667%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
    *margin-left: 83.2333333333%;
  }

  .offset-lg-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
    *margin-left: 66.5666666667%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
    *margin-left: 58.2333333333%;
  }

  .offset-lg-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
    *margin-left: 41.5666666667%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
    *margin-left: 33.2333333333%;
  }

  .offset-lg-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
    *margin-left: 16.5666666667%;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
    *margin-left: 8.2333333333%;
  }

  .offset-lg-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}

@media (min-width: 1600px) {
  .offset-xlg-11 {
    margin-left: 91.6666666667%;
    *margin-left: 91.5666666667%;
  }

  .offset-xlg-10 {
    margin-left: 83.3333333333%;
    *margin-left: 83.2333333333%;
  }

  .offset-xlg-9 {
    margin-left: 75%;
    *margin-left: 74.9%;
  }

  .offset-xlg-8 {
    margin-left: 66.6666666667%;
    *margin-left: 66.5666666667%;
  }

  .offset-xlg-7 {
    margin-left: 58.3333333333%;
    *margin-left: 58.2333333333%;
  }

  .offset-xlg-6 {
    margin-left: 50%;
    *margin-left: 49.9%;
  }

  .offset-xlg-5 {
    margin-left: 41.6666666667%;
    *margin-left: 41.5666666667%;
  }

  .offset-xlg-4 {
    margin-left: 33.3333333333%;
    *margin-left: 33.2333333333%;
  }

  .offset-xlg-3 {
    margin-left: 25%;
    *margin-left: 24.9%;
  }

  .offset-xlg-2 {
    margin-left: 16.6666666667%;
    *margin-left: 16.5666666667%;
  }

  .offset-xlg-1 {
    margin-left: 8.3333333333%;
    *margin-left: 8.2333333333%;
  }

  .offset-xlg-0 {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}

.wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.no-wrap {
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.no-wrap [class*=col-] {
  -ms-flex-negative: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
}

.wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  -webkit-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.direction-row {
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.direction-row-reverse {
  -ms-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.direction-column {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.direction-column-reverse {
  -ms-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.align-start {
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.align-end {
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.align-end [class*=col-] {
  vertical-align: bottom;
}

.align-center {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.align-center [class*=col-] {
  vertical-align: middle;
}

.align-baseline {
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}

.align-content-start {
  -ms-flex-line-pack: start;
  -webkit-align-content: flex-start;
  align-content: flex-start;
}

.align-content-end {
  -ms-flex-line-pack: end;
  -webkit-align-content: flex-end;
  align-content: flex-end;
}

.align-content-end [class*=col-] {
  vertical-align: bottom;
}

.align-content-center {
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  align-content: center;
}

.align-content-space-between {
  -ms-flex-line-pack: justify;
  -webkit-align-content: space-between;
  align-content: space-between;
}

.align-content-space-around {
  -ms-flex-line-pack: distribute;
  -webkit-align-content: space-around;
  align-content: space-around;
}

.align-self-stretch {
  -ms-flex-item-align: stretch;
  -webkit-align-self: stretch;
  align-self: stretch;
}

.align-self-start {
  -ms-flex-item-align: start;
  -webkit-align-self: flex-start;
  align-self: flex-start;
}

.align-self-end {
  -ms-flex-item-align: end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  vertical-align: bottom;
}

.align-self-center {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  vertical-align: middle;
}

.align-self-baseline {
  -ms-flex-item-align: baseline;
  -webkit-align-self: baseline;
  align-self: baseline;
  vertical-align: baseline;
}

.justify-start {
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.justify-start.grid {
  text-align: left;
}

.justify-end {
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.justify-end.grid {
  text-align: right;
  -moz-text-align-last: right;
  text-align-last: right;
}

.justify-end.grid [class*=col-] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.justify-center {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.justify-center.grid {
  text-align: center;
  -moz-text-align-last: center;
  text-align-last: center;
}

.justify-center.grid [class*=col-] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.justify-space-between {
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.justify-space-between.grid {
  text-align: justify;
  -moz-text-align-last: justify;
  text-align-last: justify;
}

.justify-space-between.grid [class*=col-] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.justify-space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.justify-space-around.grid {
  text-align: justify;
  -moz-text-align-last: justify;
  text-align-last: justify;
}

.justify-space-around.grid [class*=col-] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.grid-bleed [class*=col-] {
  padding: 0;
}

.col-grid {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.col-grid.direction-row {
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.col-bleed {
  padding: 0;
}

.col-bleed-x {
  padding: 15px 0;
}

.col-bleed-y {
  padding: 0 15px;
}

.flex-img {
  display: block;
  -ms-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
  height: auto;
  width: 100%;
  *width: auto;
}

.flex-footer {
  width: 100%;
  margin-top: auto;
  margin-bottom: 0;
}

.flex-footer>:last-child {
  margin-bottom: 0;
}

@media (min-width: 0px)and (max-width: 575px) {
  .hidden-xxs {
    display: none;
  }
}

@media (min-width: 576px) {
  .hidden-xs-up {
    display: none;
  }
}

@media (max-width: 767px) {
  .hidden-xs-down {
    display: none;
  }
}

@media (min-width: 576px)and (max-width: 767px) {
  .hidden-xs {
    display: none;
  }
}

@media (min-width: 768px) {
  .hidden-sm-up {
    display: none;
  }
}

@media (max-width: 991px) {
  .hidden-sm-down {
    display: none;
  }
}

@media (min-width: 768px)and (max-width: 991px) {
  .hidden-sm {
    display: none;
  }
}

@media (min-width: 992px) {
  .hidden-md-up {
    display: none;
  }
}

@media (max-width: 1199px) {
  .hidden-md-down {
    display: none;
  }
}

@media (min-width: 992px)and (max-width: 1199px) {
  .hidden-md {
    display: none;
  }
}

@media (min-width: 1200px) {
  .hidden-lg-up {
    display: none;
  }
}

@media (max-width: 1599px) {
  .hidden-lg-down {
    display: none;
  }
}

@media (min-width: 1200px)and (max-width: 1599px) {
  .hidden-lg {
    display: none;
  }
}

@media (min-width: 1600px) {
  .hidden-xlg {
    display: none;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: .625em;
  overflow-x: hidden;
  -webkit-overflow-scrolling: auto;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  html {
    overflow: initial;
  }
}

body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 1.9rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "PT Sans",sans-serif;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  color: #4a4a49;
}

@media only screen and (max-width: 767px) {
  body {
    overflow: initial;
  }
}

body.auth {
  background: #f5f4f6;
}

#app {
  margin-top: 9rem;
}

#app .view.view-mode svg.donut {
  display: block;
}

#app .view.comment-mode svg.donut {
  display: none;
}

@media only screen and (max-width: 767px) {
  #app {
    margin-top: 5rem;
  }

  #app .view {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
  }

  #app .view.comment-mode #sidebar {
    order: 3;
  }
}

#landing-page__nav {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1060px) {
  #landing-page__nav {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  #landing-page__nav .btn {
    font-size: 1.2rem;
    padding: .4rem .6rem;
    margin-left: 0;
  }
}

@media only screen and (max-width: 1060px) {
  #landing-page__nav .btn {
    display: block;
  }
}

#landing-page__img {
  width: 100%;
  height: auto;
  margin-bottom: 4rem;
}

a {
  text-decoration: none;
}

.container-fluid {
  padding: 0 3rem;
}

@media only screen and (max-width: 767px) {
  .container-fluid {
    padding: 0 2rem;
  }
}

.landing-footer {
  font-size: 12px;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  padding: .5rem 3rem;
  left: 0;
  width: 560px;
}

.landing-footer a {
  color: #27348b;
}

@media only screen and (max-width: 767px) {
  .landing-footer {
    bottom: unset;
    position: static;
    padding: .5rem 0rem;
    width: 100%;
  }
}

@media only screen and (min-width: 767px) {
  .landing-footer.map {
    display: none;
  }
}

@media only screen and (max-width: 1060px) {
  .landing-footer.land {
    width: 350px;
  }
}

@media only screen and (max-width: 767px) {
  .landing-footer.land {
    display: none;
    padding-top: 0;
  }
}

.align-center {
  text-align: center;
}

.pad-one {
  padding: 1rem;
}

.pad-two {
  padding: 2rem;
}

.pad-four {
  padding: 4rem;
}

.shaded {
  background-color: #f7f8f8;
}

.sentiment--1 {
  color: #c32121;
}

.sentiment--2 {
  color: #ec671c;
}

.sentiment--3 {
  color: #dfb436;
}

.sentiment--4 {
  color: #b8bf08;
}

.sentiment--5 {
  color: #75aa03;
}

.pills {
  margin: 2rem 0 0 0;
  padding: 0;
}

.pills li {
  display: inline-block;
  margin-right: 1rem;
  padding: .5rem 1rem;
  font-size: 1.6rem;
  font-weight: normal;
  border-radius: 2rem;
  line-height: 1.2;
  background-color: #27348b;
  color: #fff;
  margin-bottom: 1.5rem;
  margin-right: 1rem;
}

.cookie-confirmation {
  display: block;
  z-index: 1002;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 35rem;
  margin: 3rem;
  padding: 3rem;
  background-color: #fff;
  border-radius: 2rem;
  box-shadow: 0 2px 8px 0 rgba(29,29,27,.15);
}

.cookie-confirmation__content,
.cookie-confirmation p {
  margin: 0;
}

.cookie-confirmation__content+.cookie-confirmation__footer,
.cookie-confirmation p+.cookie-confirmation__footer {
  margin-top: 3rem;
}

.cookie-confirmation__close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 3rem;
}

.cookie-confirmation__close svg {
  fill: #8a8d8e;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.cookie-confirmation .btn-cookies {
  display: flex;
  justify-content: center;
  box-shadow: none;
  width: 100%;
}

.collapsable {
  display: flex;
}

.collapsable--column {
  flex-direction: column;
}

.collapsable--column .collapsable__content {
  overflow: hidden;
  position: relative;
  max-height: 0;
}

.collapsable--row {
  flex-direction: row;
}

.icon-tray {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  position: relative;
  right: -4rem;
  background-color: rgba(182,188,185,.1);
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  box-shadow: inset 0 1px 4px 0 rgba(73,107,108,.2);
  padding-top: 1rem;
  padding-left: 2rem;
  margin-left: -2rem;
  opacity: 0;
  max-width: 0;
  transition: all .8s ease-in-out;
}

.icon-tray__item {
  cursor: pointer;
}

.icon-tray__item+.icon-tray__item {
  margin-left: 2rem;
}

.icon-tray a:last-child {
  padding-right: 4rem;
}

.icon-tray+.btn-share {
  margin-left: 2rem;
}

.btn--inline {
  display: flex;
  flex-direction: row;
}

#icon--facebook::after {
  content: url(/images/icons/icon--facebook.svg);
  width: 1.5rem;
  height: 1.5rem;
}

#icon--twitter::after {
  content: url(/images/icons/icon--twitter.svg);
  width: 1.5rem;
  height: 1.5rem;
}

#icon--linkedin::after {
  content: url(/images/icons/icon--linkedin.svg);
  width: 1.5rem;
  height: 1.5rem;
}

#icon--whatsapp::after {
  content: url(/images/icons/icon--whatsapp.svg);
  width: 1.5rem;
  height: 1.5rem;
}

.error-message {
  color: red;
}

.modal-backdrop {
  z-index: 1003;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
}

.modal {
  z-index: 10;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0,0,0,.33);
  position: absolute;
  top: 38%;
  left: 30%;
  background-color: #fff;
  color: #27348b;
  width: 40%;
  height: fit-content;
}

@media only screen and (max-width: 1060px) {
  .modal {
    width: 80%;
    left: 10%;
    top: 20%;
  }
}

@media only screen and (max-width: 1060px)and (max-width: 767px) {
  .modal {
    top: 5%;
  }
}

.modal .split {
  width: 50%;
  padding: 1rem;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .modal .split {
    height: 40%;
    width: 100%;
  }
}

.modal .split.welsh {
  float: left;
  left: 0;
}

.modal .split.english {
  float: right;
  right: 0;
}

@media only screen and (max-width: 767px) {
  .modal .split.english {
    margin-top: 20px;
  }
}

.modal .link {
  width: 100%;
  border-width: 2px;
  border-style: solid;
  border-color: #27348b;
  border-radius: 40px;
  padding: 5px 5%;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .modal .link {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px)and (orientation: landscape) {
  .modal .link {
    padding: 0 0;
  }
}

.modal div.link:hover {
  background-color: #27348b;
  color: #fff;
}

.modal #logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  width: 40%;
  min-width: 8rem;
}

.modal h3 {
  margin: 0;
}

h1 {
  color: #27348b;
  font-size: 3.8rem;
  line-height: 4rem;
}

p {
  color: #4a4a49;
}

#header {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  padding: 1rem 0;
  box-shadow: 0 2px 8px 0 rgba(29,29,27,.15);
  background: #fff;
  z-index: 1003;
}

@media only screen and (max-width: 767px) {
  #header {
    padding: 1rem 0;
  }
}

#header .container-fluid {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 3rem;
}

@media only screen and (max-width: 767px) {
  #header .container-fluid {
    padding: 0 2rem;
  }
}

#header #logo {
  width: 15rem;
}

@media only screen and (max-width: 767px) {
  #header #logo {
    width: 8rem;
  }
}

#header #logo a {
  background: url("/images/logo.svg") no-repeat;
  display: block;
  background-size: contain;
  width: 100%;
  height: 7rem;
  text-indent: -9999em;
}

@media only screen and (max-width: 767px) {
  #header #logo a {
    height: 3.6rem;
  }
}

#header #nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
}

#header #nav li {
  display: inline-block;
}

#header #nav li a:not(.btn) {
  padding: .5rem 1rem;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 700;
  color: #27348b;
}

@media only screen and (max-width: 767px) {
  #header #nav li a:not(.btn) {
    font-size: 1.4rem;
    padding: .2rem;
  }
}

#header #nav li a:not(.btn) i {
  font-feature-settings: "liga";
  font-size: 3rem;
  vertical-align: middle;
}

@media only screen and (max-width: 767px) {
  #header #nav li .btn {
    font-size: 1.2rem;
    padding: .4rem .6rem;
    margin-left: 1.5rem;
  }

  #header #nav li .btn span {
    display: none;
  }
}

#header #nav #toggle {
  box-shadow: 0 5px 6px 0 rgba(55,76,9,.3);
  background-color: #fff;
  border-color: #27348b;
  border-style: solid;
  border-radius: 20px;
  border-width: .2rem;
  margin: -10px 1.5rem 0 1rem;
  font-weight: 700;
  font-size: 2rem;
  padding: -2px;
}

@media only screen and (max-width: 767px) {
  #header #nav #toggle {
    font-size: 1rem;
    margin: -2.5px 0 0 0;
  }
}

#header #nav #toggle .lang {
  color: #27348b;
  display: inline-block;
  padding: .5rem 1.5rem;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  #header #nav #toggle .lang {
    font-size: 1rem;
    padding: 0rem .5rem;
  }
}

#header #nav #toggle .lang.selected {
  border-radius: 20px;
  border-style: solid;
  border-color: #27348b;
  background-color: #27348b;
  color: #fff;
  margin: -0.1rem;
}

#header #nav #toggle .lang.selected a {
  color: #fff;
}

.card {
  border-radius: 2rem;
  box-shadow: 0 2px 8px 0 rgba(29,29,27,.15);
  background: #fff;
  padding: 4rem;
}

.card.card--popup {
  position: fixed;
  z-index: 1002;
  top: 10rem;
  left: 0;
  right: 0;
  padding: 4rem 2rem 3rem;
  margin: 0 auto;
  width: fit-content;
  border-radius: 2rem;
}

.card.card--popup .cookie-confirmation__close {
  margin: 1.5rem 2rem;
}

.card.card--popup .card__content h2 {
  margin-bottom: 0;
}

.card.card--popup .card__content h2:first-child {
  margin-top: 0;
}

@media only screen and (max-width: 767px) {
  .card {
    padding: 2rem;
    border-radius: 1.5rem;
  }
}

.card-header {
  margin-bottom: 3rem;
}

.card h1 {
  margin-top: 0;
}

@media only screen and (max-width: 767px) {
  .card h1 {
    font-size: 2.6rem;
    line-height: 2.8rem;
  }
}

@media only screen and (max-width: 767px) {
  .card p {
    font-size: 1.3rem;
  }
}

.card.auth {
  margin-top: 10rem;
}

@media only screen and (max-width: 767px) {
  .card.auth {
    margin-top: 6rem;
  }
}

.card h2 {
  color: #27348b;
}

#map-wrapper #map {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 90px;
  margin-left: 560px;
}

#map-wrapper #map.wide {
  margin-left: 20px;
  width: calc(100% - 20px);
}

@media only screen and (max-width: 1060px) {
  #map-wrapper #map {
    margin-left: 350px;
  }
}

@media only screen and (max-width: 767px) {
  #map-wrapper {
    padding: 1.5rem 3rem 1.5rem 1.5rem;
  }

  #map-wrapper #tooltip {
    display: none;
  }

  #map-wrapper #map {
    position: relative;
    width: 100%;
    margin-left: 0;
    top: initial;
    bottom: initial;
    left: initial;
    right: initial;
    height: 35rem;
  }
}

#sidebar {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 90px;
  width: 560px;
  min-height: calc(100vh - 90px);
  padding: 0 0 40px 0;
  box-shadow: 0 2px 8px 0 rgba(29,29,27,.15);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1002;
  background-color: #fff;
}

@media only screen and (max-width: 1060px) {
  #sidebar {
    width: 350px;
  }
}

@media only screen and (max-width: 767px) {
  #sidebar {
    position: static;
    width: 100%;
    overflow: initial;
    padding-top: .5rem;
    padding-bottom: 0;
    box-shadow: none;
    min-height: 0;
  }

  #sidebar p {
    font-size: 1.3rem;
  }
}

#sidebar h1 {
  margin-top: 4rem;
}

@media only screen and (max-width: 767px) {
  #sidebar h1 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 2.6rem;
    line-height: 2.8rem;
  }
}

#sidebar .close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  color: #27348b;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  #sidebar .close {
    top: 1rem;
    right: 1rem;
  }
}

#sidebar .close svg {
  width: 1.5rem;
  height: 1.5rem;
}

#sidebar.narrow {
  width: 28px;
  overflow-y: hidden;
  cursor: pointer;
}

#sidebar .chevron {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateX(-37px) rotate(-90deg);
  cursor: pointer;
  z-index: 1000;
  width: 100px;
  font-weight: 700;
  color: #27348b;
}

.view-comment {
  position: relative;
}

.view-comment .alert {
  background-color: #27348b;
  padding: 1.8rem 4rem;
  line-height: 2.2rem;
}

@media only screen and (max-width: 767px) {
  .view-comment .alert {
    padding: 1rem 2rem;
  }
}

.view-comment .alert span {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .view-comment .alert span {
    font-size: 1.4rem;
  }
}

.view-comment .alert+.alert {
  border-top: 1px solid #fff;
}

.view-comment .header {
  padding: 4rem;
}

@media only screen and (max-width: 767px) {
  .view-comment .header {
    padding: 2rem;
  }
}

.view-comment .header span {
  font-size: 1.3rem;
  font-weight: bold;
  display: inline-block;
}

.view-comment .header span.date {
  color: #8a8d8e;
  font-weight: normal;
  margin-left: .2rem;
}

.view-comment .header h1 {
  margin-top: 1rem !important;
  margin-bottom: 0;
}

.view-comment .comment-images ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.view-comment .comment-images ul li {
  flex: 0 0 calc(33% - 1rem);
}

.view-comment .comment-images ul li a {
  border-radius: 1rem;
  border: .1rem solid #c6c9cb;
  padding: .5rem;
  display: flex;
  min-height: 14rem;
  overflow: hidden;
}

.view-comment .comment-images ul li a img {
  max-width: 100%;
  max-height: 100%;
  border-radius: .5rem;
  overflow: hidden;
  object-fit: cover;
}

.view-comment .comment-images ul li a.unmoderated {
  overflow: hidden;
}

.view-comment .comment-images ul li a.unmoderated img {
  -ms-filter: "progid:DXImageTransform.Microsoft.MotionBlur(strength=50)";
  -webkit-filter: blur(15px);
  filter: blur(15px);
}

.view-comment .comment-images ul li+li {
  margin-left: 1rem;
}

@media screen and (max-width: 1060px) {
  .view-comment .comment-images ul li {
    flex: 0 0 100%;
  }
}

@media screen and (max-width: 767px) {
  .view-comment .comment-images ul li {
    max-width: 26rem;
  }
}

.view-comment .body {
  padding: 3rem 4rem;
  background-color: #fff;
}

@media only screen and (max-width: 767px) {
  .view-comment .body {
    padding: 2rem;
  }
}

.view-comment .body h2 {
  color: #27348b;
  font-size: 3.2rem;
  line-height: 3.4rem;
  margin-top: 0;
}

@media only screen and (max-width: 767px) {
  .view-comment .body h2 {
    font-size: 2.4rem;
    line-height: 2.6rem;
  }
}

.view-comment .body h2 span {
  color: #8a8d8e;
}

.view-comment .body img {
  right: 0;
  position: absolute;
  height: 7rem;
  margin: 30px;
}

.view-comment .footer {
  padding: 0 4rem;
  background-color: #fff;
}

@media only screen and (max-width: 767px) {
  .view-comment .footer {
    margin-bottom: 2rem;
    padding: 0 2rem;
  }
}

.view-comment svg path {
  fill: #8a8d8e;
}

.view-comment.image-not-moderated svg path {
  fill: #fff;
}

.view-comment.not-moderated .header,
.view-comment.not-moderated .body h2 {
  opacity: .6;
}

.view-comment.not-moderated svg path {
  fill: #fff;
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: none;
}

.image-modal.open {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-modal__backdrop {
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,.7);
}

.image-modal__inner {
  display: flex;
  position: relative;
  z-index: 3;
  height: calc(95vh - 9rem);
  max-width: 95vw;
  width: auto;
  top: 4rem;
  flex: 0 1 auto;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 .4rem 2rem -1.2rem rgba(14,27,42,.5);
}

@media screen and (max-width: 767px) {
  .image-modal__inner {
    height: auto;
  }
}

.image-modal__element {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  display: block;
  object-fit: contain;
}

.image-modal__close {
  position: absolute;
  right: 3rem;
  top: 3rem;
}

.modal-close {
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(0,0,0,0);
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.modal-close__text {
  color: #fff;
  font-size: 13px;
  line-height: 1;
  font-weight: 700;
  margin-right: 1rem;
}

.modal-close__icon {
  fill: #fff;
  height: 13px;
  width: 13px;
}

.landing-page {
  margin-bottom: 0rem;
}

.landing-page p {
  margin-bottom: 2rem;
}

.landing-page .btn {
  display: inline-block;
  margin-bottom: 3rem;
}

.landing-page img {
  vertical-align: middle;
  padding: 4px;
}

#logo-container {
  display: flex;
}

#logo-wg {
  width: 21rem;
  height: 7rem;
  background: url("/images/wg-logo.png") no-repeat;
  background-size: contain;
}

#logo-wg.landing {
  bottom: 0;
  right: 0;
  display: inline-block;
  margin: 0rem 0 3rem 0;
}

@media only screen and (max-width: 1060px) {
  #logo-wg.landing {
    position: relative;
  }
}

@media only screen and (max-width: 1060px)and (max-width: 1060px) {
  #logo-wg.landing {
    display: inline-block;
    width: 15rem;
    height: 5rem;
    margin: 0 0 3rem 0;
    float: right;
  }
}

#logo-wg.map {
  display: none;
}

@media only screen and (max-width: 767px) {
  #logo-wg.map {
    position: relative;
    width: 15rem;
    height: 5rem;
    display: block;
    float: right;
  }
}

*,
*:focus,
*:hover {
  outline: none;
}

.mapboxgl-popup-content {
  border-radius: 2rem;
  padding: 1rem 2rem;
  font-family: "PT Sans",sans-serif;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .mapboxgl-popup-content {
    padding: 1rem;
  }
}

.mapboxgl-popup-content h1 {
  margin-top: 0;
  font-size: 3.2rem;
}

@media only screen and (max-width: 767px) {
  .mapboxgl-popup-content h1 {
    font-size: 2.6rem;
  }
}

.mapboxgl-popup-content .form-item {
  margin-top: 2rem;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .mapboxgl-popup-content .form-item {
    margin-top: 1rem;
  }
}

#attribution {
  background-color: rgba(255,255,255,.5);
  font: 12px "Helvetica Neue",Arial,Helvetica,sans-serif;
  bottom: 5px;
  position: absolute;
  right: 125px;
  max-width: calc(100vw - 300px);
  z-index: 9;
  visibility: hidden;
}

#attribution:hover {
  margin: 10px;
  padding: 0;
  visibility: visible;
}

#attribution {
  bottom: 5px;
  right: 125px;
  max-width: 100%;
}

.deetu-static {
  bottom: 5px;
  position: absolute;
  width: 85px;
  z-index: 2;
}

.deetu-static a img {
  width: 85px;
}

.map-geocoder {
  position: absolute;
  z-index: 1;
  width: 75%;
  left: 10px;
  top: 10px;
}

.mapboxgl-ctrl-geocoder {
  min-width: 100%;
}

.mapboxgl-ctrl-geocoder #map-geocoder .mapboxgl-ctrl-geocoder--input {
  padding-left: 0;
}

.mapboxgl-ctrl-geocoder #geocoder input {
  padding-left: 3rem !important;
}

.mapboxgl-ctrl-bottom-right::after {
  bottom: 1px;
  position: absolute;
  right: -20px;
  visibility: visible;
}

.mapboxgl-ctrl-top-right {
  right: 10px;
}

@media only screen and (max-width: 767px) {
  .mapboxgl-ctrl-top-right {
    right: 0;
  }
}

.deetu-static {
  bottom: 10px;
  position: fixed;
  right: 10px;
  width: 100px;
  z-index: 9;
}

button.home-button,
button.light-style-button,
button.satellite-style-button,
button.accessible-button {
  font-family: "Material Icons";
  font-feature-settings: "liga";
  font-size: 20px;
  line-height: 24px;
  vertical-align: middle;
}

button.home-button::before {
  content: "home";
}

button.light-style-button::before {
  content: "brightness_5";
}

button.satellite-style-button::before {
  content: "satellite";
}

button.accessible-button::before {
  content: "accessibility_new";
}

.donut-text {
  font-family: "PT Sans",sans-serif;
  font-weight: 700;
}

.donut {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  #attribution {
    bottom: 35px;
    right: 20px;
    max-width: 100%;
  }

  .deetu-static {
    bottom: 5px;
    position: absolute;
    width: 85px;
  }

  .deetu-static a img {
    width: 85px;
  }
}

svg>* {
  pointer-events: none;
}

#map-geocoder {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px 2px rgba(0,0,0,.1);
  height: 36px;
  left: 10px;
  position: absolute;
  display: inline-flex;
  top: 10px;
  width: 240px;
  z-index: 1000;
}

#map-geocoder .mapboxgl-ctrl-geocoder {
  box-shadow: none;
  min-width: 190px;
  width: 190px;
}

@media only screen and (max-width: 767px) {
  #map-geocoder .mapboxgl-ctrl-geocoder {
    min-width: 120px;
  }
}

#map-geocoder .mapboxgl-ctrl-geocoder--input {
  border: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  box-shadow: none;
  display: inline-block;
  left: 40px;
  min-width: 160px;
  position: absolute;
  width: 160px;
  height: 36px;
  padding-left: 0;
  box-shadow: none;
}

@media only screen and (max-width: 767px) {
  #map-geocoder .mapboxgl-ctrl-geocoder--input {
    width: 120px;
    min-width: 120px;
  }
}

#legend-container {
  background-color: #fff;
  border-collapse: collapse;
  border-radius: 4px;
  box-shadow: 0 0 10px 2px rgba(0,0,0,.1);
  cursor: default;
  left: 10px;
  position: absolute;
  width: 270px;
  max-width: 35%;
  top: 48px;
  z-index: 999;
}

#legend-container input {
  cursor: pointer;
}

#legend-container .bua {
  margin-left: 135px;
}

@media only screen and (max-width: 767px) {
  #legend-container .bua {
    margin-left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  #legend-container {
    max-width: 240px;
    overflow-y: auto;
    height: 200px;
  }
}

tr.legend-element {
  border-bottom: 1px solid #ddd;
  color: rgba(0,0,0,.75);
  font-family: "Open Sans","Helvetica Neue",Arial,Helvetica,sans-serif;
  font-size: 14px;
  height: 36px;
  line-height: 14px;
  padding: 10.5px 42px;
  vertical-align: center;
  cursor: pointer;
}

tr.legend-element input {
  cursor: pointer;
  position: absolute;
  opacity: 0;
}

tr.legend-element input+label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

tr.legend-element input+label:before {
  border-radius: 50%;
  border: 1px solid rgba(117,117,117,.5);
  content: "";
  margin: 0 12px 0 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: #fff;
}

tr.legend-element input:hover+label:before {
  background: rgba(117,117,117,.25);
}

tr.legend-element input:checked+label:before {
  background: #757575;
}

tr.legend-element input:checked+label:after {
  content: "";
  position: absolute;
  left: 14.5px;
  top: 9px;
  background: #fff;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #fff,4px 0 0 #fff,4px -2px 0 #fff,4px -4px 0 #fff,4px -6px 0 #fff,4px -8px 0 #fff;
  transform: rotate(45deg);
}

span.land {
  display: inline-block;
  vertical-align: middle;
}

th,
span.land {
  width: 42px;
}

th div,
span.land div {
  margin: 0 6px;
  width: 30px;
}

.blue-dashed-line div {
  border-top: 6px dashed #afb7e9;
  border-radius: 4px;
}

.blue-solid-line div {
  border-top: 6px solid #8893dd;
  border-radius: 4px;
}

.orange-dashed-line div {
  border-top: 6px dashed #f08242;
  border-radius: 4px;
}

.green-solid-line div {
  border-top: 6px solid #5da554;
  border-radius: 4px;
}

.green-dotted-line div {
  border-top: 6px dotted #5da554;
}

.yellow-solid-line div {
  border-top: 6px solid #e3be4f;
  border-radius: 4px;
}

.grey-dashed-line div {
  border-top: 6px dashed #c4c4c4;
  border-radius: 4px;
}

.yellow-solid-line div {
  border-top: 6px solid #e3be4f;
  border-radius: 4px;
}

.legend-element.last-element {
  border-bottom: none;
}

th.green-circle div {
  background-color: #75aa03;
  border: 9px solid #75aa03;
  border-radius: 9px;
  width: 18px;
}

th.light-green-circle div {
  background-color: #b8bf08;
  border: 9px solid #b8bf08;
  border-radius: 9px;
  width: 18px;
}

th.yellow-circle div {
  background-color: #dfb436;
  border: 9px solid #dfb436;
  border-radius: 9px;
  width: 18px;
}

th.orange-circle div {
  background-color: #ec671c;
  border: 9px dashed #ec671c;
  border-radius: 9px;
  width: 18px;
}

th.red-circle div {
  background-color: #c32121;
  border: 9px solid #c32121;
  border-radius: 9px;
  width: 18px;
}

#legend-btn {
  text-align: left;
  background-color: #fff;
  border-width: 0;
  border-right: 1px solid #ddd;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  cursor: pointer;
  display: inline-block;
  height: 36px;
  line-height: 14px;
  min-width: 135px;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  #legend-btn {
    min-width: 40px;
    width: 40px;
  }
}

#legend-btn span {
  color: #757575;
  display: inline-block;
  padding: 5px 8px;
  vertical-align: middle;
}

#legend-btn span.legend-btn-span {
  font-size: 14px;
  padding-left: 0;
}

@media only screen and (max-width: 767px) {
  #legend-btn span.legend-btn-span {
    display: none;
  }
}

#legend-btn.selected {
  background-color: #ddd;
}

.mapboxgl-popup-content {
  border-radius: 4px;
  min-width: 200px;
}

#tooltip {
  background-color: #fff;
  border-radius: 4px;
  color: #757575;
  font-family: "PT Sans",sans-serif;
  font-size: 14px;
  position: absolute;
  max-width: 135px;
  width: 130px;
  padding: 5px;
}

#tooltip.point-up {
  margin-top: 10px;
}

#tooltip.point-right {
  margin-right: 10px;
}

#tooltip .pointer {
  width: 10px;
  height: 10px;
  position: absolute;
  transform: rotate(45deg);
  background-color: #fff;
  z-index: -1;
  top: -5px;
}

#tooltip .pointer.right {
  right: -5px;
  top: 10px;
}

#geolocation {
  -webkit-appearance: none;
  appearance: none;
  color: #27348b;
  background-color: rgba(0,0,0,0);
  padding: 0;
  text-decoration: underline;
  margin-top: .5rem;
  border: none;
  outline: none !important;
  font-family: "PT Sans",sans-serif;
  cursor: pointer;
  display: none;
  text-align: left;
}

#geolocation:disabled {
  opacity: .5;
}

#geolocation.error {
  color: red;
}

@media screen and (max-width: 767px) {
  #geolocation {
    display: block;
  }
}

.mapboxgl-ctrl-geolocate {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .mapboxgl-ctrl-geolocate {
    display: block !important;
  }
}

.btn {
  box-shadow: 0 5px 6px 0 rgba(55,76,9,.3);
  padding: 1rem 2rem;
  border-radius: 2rem;
  border: .2rem solid #27348b;
  color: #27348b;
  text-decoration: none;
  font-family: "PT Sans",sans-serif;
  font-weight: bold;
  font-size: 1.6rem;
  text-align: center;
  text-align-last: center;
  cursor: pointer;
}

.btn-green {
  background-color: #81bc00;
  border-color: #81bc00;
  color: #fff;
}

.btn-green:disabled {
  background-color: #b6bcb9;
  border-color: #b6bcb9;
}

.btn-orange {
  background-color: #ec671c;
  border-color: #ec671c;
  color: #fff;
}

.btn-brand {
  background-color: #27348b;
  border-color: #27348b;
  color: #fff;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-like {
  background-color: #a1b2ba;
  border-color: #a1b2ba;
  color: #fff;
  font-size: 2.2rem;
  line-height: 2.2rem;
  padding: .6rem 1.7rem;
}

.btn-like.active {
  background-color: #248dc1;
  border-color: #248dc1;
}

.btn-like:after {
  content: url(/images/like.svg);
  width: 1.9rem;
  height: 1.9rem;
}

@media only screen and (max-width: 767px) {
  .btn-like {
    font-size: 1.8rem;
    line-height: 2rem;
    padding: .4rem 1.2rem;
  }

  .btn-like:after {
    width: 1.2rem;
    height: 1.2rem;
  }
}

.btn-like span {
  margin-right: .8rem;
}

.btn-share {
  background-color: #81bc00;
  border-color: #81bc00;
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.7rem;
  padding: 1rem 1.7rem;
  z-index: 1;
}

.btn-comment {
  padding-left: 6rem;
  padding-right: 6rem;
}

@media only screen and (max-width: 767px) {
  .btn-comment {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
    font-size: 16px;
  }
}

.btn-circle {
  border-radius: 50%;
  padding: .5rem 1.8rem;
  font-size: 28px;
}

.form-intro {
  margin-bottom: 4rem;
}

.form-group {
  margin-bottom: 2rem;
}

.form-group label {
  display: block;
  color: #27348b;
  font-weight: bold;
  font-size: 1.6rem;
  margin-bottom: .5rem;
}

.form-group label.form-check-label {
  font-weight: normal;
  color: #4a4a49;
}

.form-item {
  padding: 4rem 3rem;
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .form-item {
    padding: 2rem 1.5rem;
  }
}

.form-item>div {
  flex-grow: 1;
}

.form-item>div:not(.num) {
  max-width: calc(100% - 4rem);
}

.form-item .num {
  font-size: 4.8rem;
  font-weight: bold;
  color: #b6bcb9;
  margin-right: 1.5rem;
  width: 2.5rem;
  flex-grow: 0;
}

@media only screen and (max-width: 767px) {
  .form-item .num {
    font-size: 2.4rem;
    margin-right: 1rem;
  }
}

.form-item label {
  color: #27348b;
  font-size: 3.2rem;
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .form-item label {
    font-size: 2rem;
  }
}

.form-item p {
  margin-top: 1rem;
  font-size: 1.3rem;
}

.form-item.geocode {
  padding: 0rem 3rem 4rem 3rem;
}

.form-item.geocode label {
  color: #27348b;
  font-size: 1.6rem;
  line-height: 1.7rem;
  font-weight: bold;
  margin-bottom: .5rem;
  display: block;
}

.form-item.geocode .mapboxgl-ctrl-geocoder {
  width: 100%;
  max-width: 100%;
  box-shadow: none;
}

.form-item.geocode .mapboxgl-ctrl-geocoder input {
  padding-left: 3rem;
}

input[type=text],
input[type=email],
input[type=password],
textarea,
select {
  border: 1px solid #b6bcb9;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  padding: .5rem 1rem;
  background-color: #fff;
  outline: none;
  font-size: 1.4rem;
  max-width: 100%;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus,
textarea:focus,
select:focus {
  border-color: #402051;
}

#geocoder input {
  padding-left: 3rem !important;
}

.radio .option {
  display: block;
  position: relative;
  padding: 3px 10px 0 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-style: solid;
  border-color: #27348b;
  border-radius: 20px;
  border-width: .2rem;
  color: #4a4a49;
  font-weight: normal;
  height: 30px;
  align: left;
  width: fit-content;
}

.radio .option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  background-color: #eee;
  border-radius: 50%;
}

.radio .option:hover input~.checkmark {
  background-color: #ccc;
}

.radio .option input:checked~.checkmark {
  background-color: #27348b;
  height: 28px;
  width: 28px;
  margin: -0.1rem;
}

textarea {
  height: 158px;
}

.button-group-pills label {
  display: inline-block;
  font-size: 1.6rem;
  font-weight: normal;
  border-radius: 2rem;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  margin-left: 1rem;
  border: 1px solid #27348b;
  background-color: #fff;
  color: #27348b;
  padding: .5rem 1rem;
  cursor: pointer;
}

@media only screen and (max-width: 1060px) {
  .button-group-pills label {
    font-size: 1.2rem;
    padding: .3rem .6rem;
    margin-left: .5rem;
  }
}

.button-group-pills label.selected {
  border-color: #27348b;
  background-color: #27348b;
  color: #fff;
  font-weight: bold;
  box-shadow: none;
}

.button-group-pills label input {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

#map-geocoder {
  max-width: 35%;
}

@media only screen and (max-width: 767px) {
  #map-geocoder {
    max-width: 100%;
  }
}

.mapboxgl-ctrl-geocoder--icon-search {
  top: 10px;
  left: 8px;
  width: 20px;
  height: 20px;
}

#errors li {
  color: red;
  list-style-type: none;
}

#image-dropzone {
  background-color: rgba(0,0,0,0);
  border: 2px dashed #27348b;
  border-radius: .5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-family: "PT Sans",sans-serif;
}

#image-dropzone .dz-default {
  color: #27348b;
}

#image-dropzone .dz-preview {
  display: flex;
  width: 100%;
  border-radius: .5rem;
  overflow: hidden;
  margin: 0;
}

#image-dropzone .dz-preview+.dz-preview {
  margin-top: 1rem;
}

#image-dropzone .dz-preview .dz-image {
  flex: 0 1 100px;
}

#image-dropzone .dz-preview .dz-details {
  z-index: 20;
  position: relative;
  flex: 1 1 auto;
  left: 0;
  opacity: 1;
  font-family: "PT Sans",sans-serif;
  font-size: 15px;
  min-width: 0;
  max-width: none;
  padding: 2em 1em;
  line-height: 1;
  background-color: #27348b;
  display: flex;
  flex-direction: column-reverse;
}

#image-dropzone .dz-preview .dz-details .dz-filename {
  margin-bottom: .5rem;
}

#image-dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 0;
}

#image-dropzone .dz-preview .dz-remove {
  padding: 0;
  font-size: 0;
  line-height: 0;
  top: 1rem;
  right: 1rem;
  bottom: auto;
  width: 1rem;
  left: auto;
  border: none;
  display: block !important;
  opacity: 1;
  z-index: 51;
}

#image-dropzone .dz-preview .dz-remove:after {
  content: url("data:image/svg+xml;base64,PHN2ZwogICAgdmVyc2lvbj0iMS4xIgogICAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICAgdmlld0JveD0iMCAwIDM0IDM0IgogICAgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzQgMzQ7IgogICAgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIKICAgIHdpZHRoPSIxMCIKICAgIGhlaWdodD0iMTAiCj4KICAgIDxwYXRoIGQ9Ik0zMy42LDUuM0w1LjMsMzMuNmwtNC45LTQuOUwyOC43LDAuNEwzMy42LDUuM3oiIGZpbGw9IndoaXRlIj48L3BhdGg+CiAgICA8cGF0aCBkPSJNNS4zLDAuNGwyOC4zLDI4LjNsLTQuOSw0LjlMMC40LDUuM0w1LjMsMC40eiIgZmlsbD0id2hpdGUiPjwvcGF0aD4KPC9zdmc+Cg==");
  height: 1rem;
  width: 1rem;
  display: block;
}

#image-dropzone .dz-preview.dz-error .dz-error-mark {
  display: none !important;
}

#image-dropzone .dz-preview.dz-error .dz-error-message {
  z-index: 50;
  position: absolute;
  top: 0;
  margin: auto;
  opacity: 1;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255,0,0,.85);
  font-weight: 700;
  font-size: 1.5rem;
}

#image-dropzone .dz-preview:hover .dz-image img {
  transform: scale(1, 1);
  filter: blur(0);
}

