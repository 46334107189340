.mapboxgl-popup-content {
    border-radius: 2rem;
    padding: 1rem 2rem;
    font-family: "PT Sans", sans-serif;
    font-size: 14px;

    @media only screen and (max-width: $responsiveMobile) {
        padding: 1rem;
    }

    h1 {
        margin-top: 0;
        font-size: 3.2rem;
        @media only screen and (max-width: $responsiveMobile) {
            font-size: 2.6rem;
        }
    }

    .form-item {
        margin-top: 2rem;
        padding: 0;

        @media only screen and (max-width: $responsiveMobile) {
            margin-top: 1rem;
        }
    }
}

@import "~@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

#attribution {
    background-color: rgba(255, 255, 255, 0.5);
    font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    bottom: 5px;
    position: absolute;
    right: 125px;
    max-width: calc(100vw - 300px);
    z-index: 9;
    visibility: hidden;
}

#attribution:hover {
    margin: 10px;
    padding: 0;
    visibility: visible;
}

#attribution {
    bottom: 5px;
    right: 125px;
    max-width: 100%;
}

.deetu-static {
    bottom: 5px;
    position: absolute;
    width: 85px;
    z-index: 2;
}

.deetu-static a img {
    width: 85px;
}

.map-geocoder {
    position: absolute;
    z-index: 1;
    width: 75%;
    left: 10px;
    top: 10px;
}

.mapboxgl-ctrl-geocoder {
    min-width: 100%;

    #map-geocoder {
        .mapboxgl-ctrl-geocoder--input {
            padding-left: 0
        }
    }

    #geocoder {
        input {
            padding-left: 3rem !important;
        }
    }
}

.mapboxgl-ctrl-bottom-right::after {
    bottom: 1px;
    position: absolute;
    right: -20px;
    visibility: visible;
}

.mapboxgl-ctrl-top-right {
    right: 10px;
    @media only screen and (max-width: $responsiveMobile) {
        right: 0;
    }
}

.deetu-static {
    bottom: 10px;
    position: fixed;
    right: 10px;
    width: 100px;
    z-index: 9;
}

button.home-button,
button.light-style-button,
button.satellite-style-button,
button.accessible-button {
    font-family: "Material Icons";
    font-feature-settings: 'liga';
    font-size: 20px;
    line-height: 24px;
    vertical-align: middle;
}

button.home-button::before {
    content: "home";
}

button.light-style-button::before {
    content: "brightness_5";
}

button.satellite-style-button::before {
    content: "satellite";
}

button.accessible-button::before {
    content: "accessibility_new"
}

.donut-text {
    font-family: "PT Sans", sans-serif;
    font-weight: 700;
}

.donut {
    cursor: pointer;
}

@media only screen and (max-width: $responsiveMobile) {
    #attribution {
        bottom: 35px;
        right: 20px;
        max-width: 100%;
    }

    .deetu-static {
        bottom: 5px;
        position: absolute;
        width: 85px;
    }

    .deetu-static a img {
        width: 85px;
    }
}

// so event listener target will only be
// the svg and not any child elements
svg > * {
    pointer-events: none;
}

#map-geocoder {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, .1);
    height: 36px;
    left: 10px;
    position: absolute;
    display: inline-flex;
    top: 10px;
    /* NEW fixed width */
    width: 240px;
    z-index: 1000;

    .mapboxgl-ctrl-geocoder {
        box-shadow: none;
        min-width: 190px;
        width: 190px;
        @media only screen and (max-width: $responsiveMobile) {
            min-width: 120px;
        }
    }

    .mapboxgl-ctrl-geocoder--input {
        border: none;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        box-shadow: none;
        display: inline-block;
        left: 40px;
        min-width: 160px;
        position: absolute;
        width: 160px;
        height: 36px;
        padding-left: 0;
        box-shadow: none;
        @media only screen and (max-width: $responsiveMobile) {
            width: 120px;
            min-width: 120px;
        }
    }
}

#legend-container {
    background-color: white;
    border-collapse: collapse;
    border-radius: 4px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, .1);
    cursor: default;
    /* left - equal to #geocoder{left} */
    left: 10px;
    position: absolute;
    width: 270px;
    max-width: 35%;
    /* top - equal to #geocoder{top}+36px */
    top: 48px;
    z-index: 999;

    input {
        cursor: pointer;
    }

    .bua {
        margin-left: 135px;
        @media only screen and (max-width: $responsiveMobile) {
            margin-left: 40px;
        }
    }

    @media only screen and (max-width: $responsiveMobile) {
        max-width: 240px;
        overflow-y: auto;
        height: 200px;
    }
}


tr.legend-element {
    border-bottom: 1px solid #ddd;
    color: rgba(0, 0, 0, 0.75);
    font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 14px;
    height: 36px;
    line-height: 14px;
    padding: 10.5px 42px;
    vertical-align: center;
    cursor: pointer;

    input {
        cursor: pointer;
        position: absolute; // take it out of document flow
        opacity: 0; // hide it

        & + label {
            position: relative;
            cursor: pointer;
            padding: 0;
        }

        // Box.
        & + label:before {
            border-radius: 50%;
            border: 1px solid rgba(117, 117, 117, 0.5);
            content: '';
            margin: 0 12px 0 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 20px;
            height: 20px;
            background: white;
        }

        // Box hover
        &:hover + label:before {
            background: rgba(117, 117, 117, 0.25);
        }

        // Box focus
        &:focus + label:before {
            // box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
        }

        // Box checked
        &:checked + label:before {
            background: rgba(117, 117, 117, 1);
        }

        // Checkmark. Could be replaced with an image
        &:checked + label:after {
            content: '';
            position: absolute;
            left: 14.5px;
            top: 9px;
            background: white;
            width: 2px;
            height: 2px;
            box-shadow: 2px 0 0 white,
            4px 0 0 white,
            4px -2px 0 white,
            4px -4px 0 white,
            4px -6px 0 white,
            4px -8px 0 white;
            transform: rotate(45deg);
        }

    }
}

span.land {
    display: inline-block;
    vertical-align: middle;
}

th, span.land {
    width: 42px;
}

th div, span.land div {
    margin: 0 6px;
    width: 30px;
}

.blue-dashed-line div {
    border-top: 6px dashed #afb7e9;
    border-radius: 4px;
}

.blue-solid-line div {
    border-top: 6px solid #8893dd;
    border-radius: 4px;
}

.orange-dashed-line div {
    border-top: 6px dashed #f08242;
    border-radius: 4px;
}

.green-solid-line div {
    border-top: 6px solid #5da554;
    border-radius: 4px;
}

.green-dotted-line div {
    border-top: 6px dotted #5da554;
}

.yellow-solid-line div {
    border-top: 6px solid #e3be4f;
    border-radius: 4px;
}

.grey-dashed-line div {
    border-top: 6px dashed #c4c4c4;
    border-radius: 4px;
}

.yellow-solid-line div {
    border-top: 6px solid #e3be4f;
    border-radius: 4px;
}

.legend-element.last-element {
    border-bottom: none;
}

th.green-circle div {
    background-color: #75AA03;
    border: 9px solid #75AA03;
    border-radius: 9px;
    width: 18px;
}

th.light-green-circle div {
    background-color: #B8BF08;
    border: 9px solid #B8BF08;
    border-radius: 9px;
    width: 18px;
}

th.yellow-circle div {
    background-color: #DFB436;
    border: 9px solid #DFB436;
    border-radius: 9px;
    width: 18px;
}

th.orange-circle div {
    background-color: #EC671C;
    border: 9px dashed #EC671C;
    border-radius: 9px;
    width: 18px;
}

th.red-circle div {
    background-color: #C32121;
    border: 9px solid #C32121;
    border-radius: 9px;
    width: 18px;
}

#legend-btn {
    text-align: left;
    background-color: white;
    border-width: 0;
    border-right: 1px solid #ddd;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    cursor: pointer;
    display: inline-block;
    height: 36px;
    line-height: 14px;
    @media only screen and (max-width: $responsiveMobile) {
        min-width: 40px;
        width: 40px;
    }
    min-width: 135px;
    padding: 0;

    span {
        color: #757575;
        display: inline-block;
        padding: 5px 8px;
        vertical-align: middle;
    }

    span.legend-btn-span {
        @media only screen and (max-width: $responsiveMobile) {
            display: none;
        }
        font-size: 14px;
        padding-left: 0;
    }

    &.selected {
        background-color: #ddd;
    }
}

.mapboxgl-popup-content {
    border-radius: 4px;
    min-width: 200px;
}

#tooltip {
    background-color: white;
    border-radius: 4px;
    color: #757575;
    font-family: "PT Sans", sans-serif;
    font-size: 14px;
    position: absolute;
    max-width: 135px;
    width: 130px;
    padding: 5px;

    &.point-up {
        margin-top: 10px;
    }

    &.point-right {
        margin-right: 10px;
    }

    .pointer {
        width: 10px;
        height: 10px;
        position: absolute;
        transform: rotate(45deg);
        background-color: white;
        z-index: -1;
        top: -5px;

        &.right {
            right: -5px;
            top: 10px;
        }
    }
}


#geolocation {
    -webkit-appearance: none;
    appearance: none;
    color: $brand-1;
    background-color: transparent;
    padding: 0;
    text-decoration: underline;
    margin-top: .5rem;
    border: none;
    outline: none !important;
    font-family: $font-family;
    cursor: pointer;
    display: none;
    text-align: left;

    &:disabled {
        opacity: .5;
    }

    &.error {
        color: $error-color;
    }

    @media screen and (max-width: 767px) {
        display: block;
    }
}

.mapboxgl-ctrl-geolocate {
    display: none !important;

    @media screen and (max-width: 767px) {
        display: block !important;
    }
}
