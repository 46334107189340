#header{
	position: fixed;
    right: 0;
    left: 0;
    top: 0;
	padding: 1rem 0;
	box-shadow: 0 2px 8px 0 $box-shadow;
	background: #ffffff;
    z-index: 1003;

	@media only screen and (max-width: $responsiveMobile){
		padding: 1rem 0;
	}

	.container-fluid{
		@include display-flex;
		@include justify-content-space-between;
		@include align-items(center);
		padding: 0 3rem;

		@media only screen and (max-width: $responsiveMobile){
			padding: 0 2rem;
		}
	}

	#logo{
		width: 15rem;

		@media only screen and (max-width: $responsiveMobile){
			width: 8rem;
		}

		a{
			background: url('/images/logo.svg') no-repeat;
		    display: block;
		    background-size: contain;
		    width: 100%;
		    height: 7rem;
		    text-indent: -9999em;

		    @media only screen and (max-width: $responsiveMobile){
				height: 3.6rem;
			}
		}
	}

	#nav{
		list-style: none;
		padding: 0;
		margin: 0;
		display: inline-block;

		li{
			display: inline-block;
			a:not(.btn){
				padding: 0.5rem 1rem;
				text-decoration: none;
				font-size: 2rem;
				font-weight: 700;
				color: $brand-1;

				@media only screen and (max-width: $responsiveMobile){
					font-size: 1.4rem;
					padding: 0.2rem;
				}

				i {
					font-feature-settings: 'liga';
					font-size: 3rem;
					vertical-align: middle;
				}
			}

			.btn{
				@media only screen and (max-width: $responsiveMobile){
					font-size: 1.2rem;
					padding: 0.4rem 0.6rem;
					margin-left: 1.5rem;

					span{
						display: none;
					}
				}
			}
		}
		#toggle{
			box-shadow: 0 5px 6px 0 rgba(55, 76, 9, 0.3);
			background-color: white;
			border-color: $brand-1;
			border-style: solid;
			border-radius: 20px;
			border-width: 0.2rem;
			margin: -10px 1.5rem 0 1rem;
			font-weight: 700;
			font-size: 2rem;
			padding: -2px;

			@media only screen and (max-width: $responsiveMobile){
				font-size: 1rem;
				margin: -2.5px 0 0 0;
			}
			.lang{
				color: $brand-1;
				display: inline-block;
				padding: 0.5rem 1.5rem;
				cursor: pointer;

				@media only screen and (max-width: $responsiveMobile){
					font-size: 1rem;
					padding: 0rem 0.5rem;
				}

				&.selected{
					border-radius: 20px;
					border-style: solid;
					border-color: $brand-1;
					background-color: $brand-1;
					color: white;
					margin: -0.1rem;
					a{
						color: white;
					}
				}
			}
		}
	}
}
