*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    font-size: 0.625em;
    overflow-x: hidden;
    -webkit-overflow-scrolling: auto;
    height: 100%;

    @media only screen and (max-width: $responsiveMobile) {
        overflow: initial;
    }
}

body {
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 1.9rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $font-family;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    color: $body-text;

    @media only screen and (max-width: $responsiveMobile) {
        overflow: initial;
    }

    &.auth {
        background: #F5F4F6;
    }
}

#app {
    margin-top: 9rem;

    .view {
        &.view-mode svg.donut {
            // show donut cluster in view mode
            display: block;
        }

        &.comment-mode svg.donut {
            // hide donut cluster in comment mode
            display: none;
        }
    }

    @media only screen and (max-width: $responsiveMobile) {
        margin-top: 5rem;

        .view {
            @include display-flex;
            flex-direction: column;

            &.comment-mode {
                #sidebar {
                    order: 3;
                }
            }
        }
    }
}

#landing-page__nav {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 1060px) {
        display: block;
    }

    .btn {
        @media only screen and (max-width: $responsiveMobile) {
            font-size: 1.2rem;
            padding: 0.4rem 0.6rem;
            margin-left: 0;
        }
        @media only screen and (max-width: $responsiveTablet) {
            display: block;
        }
    }
}

#landing-page__img {
    width: 100%;
    height: auto;
    margin-bottom: 4rem;
}

a {
    text-decoration: none;
}

.container-fluid {
    padding: 0 3rem;

    @media only screen and (max-width: $responsiveMobile) {
        padding: 0 2rem;
    }
}

.landing-footer {
    font-size: 12px;
    background-color: #FFF;
    position: fixed;
    bottom: 0;
    padding: 0.5rem 3rem;
    left: 0;
    width: 560px;

    a {
        color: $brand-1;
    }

    @media only screen and (max-width: $responsiveMobile) {
        bottom: unset;
        position: static;
        padding: 0.5rem 0rem;
        width: 100%;
    }

    &.map {
        // display: inline;
        @media only screen and (min-width: $responsiveMobile) {
            display: none;
        }
    }

    &.land {
        // display: inline-block;
        @media only screen and (max-width: $responsiveTablet) {
            width: 350px;
        }
        @media only screen and (max-width: $responsiveMobile) {
            display: none;
            padding-top: 0;
        }
    }
}

.align {
    &-center {
        text-align: center;
    }
}

.pad {
    &-one {
        padding: 1rem;
    }

    &-two {
        padding: 2rem;
    }

    &-four {
        padding: 4rem;
    }
}

.shaded {
    background-color: #f7f8f8;
}

.sentiment {
    &--1 {
        color: #C32121;
    }

    &--2 {
        color: #EC671C;
    }

    &--3 {
        color: #DFB436;
    }

    &--4 {
        color: #B8BF08;
    }

    &--5 {
        color: #75AA03;
    }
}

.pills {
    margin: 2rem 0 0 0;
    padding: 0;

    li {
        display: inline-block;
        margin-right: 1rem;
        padding: 0.5rem 1rem;

        font-size: 1.6rem;
        font-weight: normal;
        border-radius: 2rem;
        line-height: 1.2;
        background-color: #27348B;
        color: white;

        margin-bottom: 1.5rem;
        margin-right: 1rem;
    }
}

.cookie-confirmation {
    display: block;
    z-index: 1002;
    position: fixed;
    right: 0;
    bottom: 0;
    width: 35rem;
    margin: 3rem;
    padding: 3rem;
    background-color: #ffffff;
    border-radius: 2rem;
    box-shadow: 0 2px 8px 0 rgba(29, 29, 27, 0.15);

    &__content, p {
        margin: 0;

        & + .cookie-confirmation__footer {
            margin-top: 3rem
        }
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        margin: 3rem;

        svg {
            fill: #8A8D8E;
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;
        }
    }

    .btn-cookies {
        display: flex;
        justify-content: center;
        box-shadow: none;
        width: 100%
    }

}

// .btn + .collapsable {
// 	margin-left: 2rem;
// }

.collapsable {
    display: flex;

    &--column {
        flex-direction: column;

        .collapsable__content {
            overflow: hidden;
            position: relative;
            max-height: 0;
            // transition: all 0.6s ease-in-out;
        }
    }

    &--row {
        flex-direction: row;
    }
}

.icon-tray {
    display: flex;
    overflow: hidden;
    flex-direction: row;
    position: relative;
    right: -4rem;
    // background-color: opacify(#496B6C, 0.1);
    background-color: rgba(182, 188, 185, 0.1);
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    box-shadow: inset 0 1px 4px 0 rgba(73, 107, 108, 0.2);
    padding-top: 1rem;
    padding-left: 2rem;
    margin-left: -2rem;
    opacity: 0;

    // for slide out
    max-width: 0;
    transition: all 0.8s ease-in-out;

    &__item {
        cursor: pointer;

        & + & {
            margin-left: 2rem;
        }
    }

    a:last-child {
        padding-right: 4rem;
    }

    & + .btn-share {
        margin-left: 2rem;
    }
}

.btn--inline {
    display: flex;
    flex-direction: row;
}

#icon--facebook::after {
    content: url(/images/icons/icon--facebook.svg);
    width: 1.5rem;
    height: 1.5rem;
}

#icon--twitter::after {
    content: url(/images/icons/icon--twitter.svg);
    width: 1.5rem;
    height: 1.5rem;
}

#icon--linkedin::after {
    content: url(/images/icons/icon--linkedin.svg);
    width: 1.5rem;
    height: 1.5rem;
}

#icon--whatsapp::after {
    content: url(/images/icons/icon--whatsapp.svg);
    width: 1.5rem;
    height: 1.5rem;
}

.error-message {
    color: $error-color
}

/* Modal relatd css */
.modal-backdrop {
    z-index: 1003;
    //z-index: 1000;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal {
    z-index: 10;
    padding: 2rem;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    position: absolute;
    top: 38%;
    left: 30%;
    background-color: #FFFFFF;
    color: #27348B;
    width: 40%;
    height: fit-content;
    @media only screen and (max-width: $responsiveTablet) {
        width: 80%;
        left: 10%;
        top: 20%;
        @media only screen and (max-width: $responsiveMobile) {
            top: 5%;
        }
    }

    .split {
        width: 50%;
        padding: 1rem;
        text-align: center;
        @media only screen and (max-width: $responsiveMobile) {
            height: 40%;
            width: 100%;
        }

        &.welsh {
            /*background-color: #00FF00;*/
            float: left;
            left: 0;
        }

        &.english {
            /*background-color: #FF0000;*/
            float: right;
            right: 0;
            @media only screen and (max-width: $responsiveMobile) {
                margin-top: 20px;
            }
        }
    }

    .link {
        width: 100%;
        border-width: 2px;
        border-style: solid;
        border-color: #27348B;
        border-radius: 40px;
        padding: 5px 5%;
        cursor: pointer;
        @media only screen and (max-width: $responsiveMobile) {
            font-size: 14px;
            @media screen and (orientation: landscape) {
                padding: 0 0;
            }
        }
    }

    div.link:hover {
        background-color: #27348B;
        color: #FFFFFF;
    }

    #logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
        width: 40%;
        min-width: 8rem;
    }

    h3 {
        margin: 0;
    }
}
